/**
 * @deprecated remove in 4.0
 */
$space: 0.25rem;

@import 'mixins/browsers.scss';
@import 'mixins/mixins.scss';
@import 'mixins/picker.scss';
@import 'mixins/slider.scss';
@import 'mixins/text.scss';
@import 'mixins/textfield.scss';
@import 'mixins/wrapper.scss';
@import 'variables/media.scss';
