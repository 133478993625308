@import "@taiga-ui/core/styles/taiga-ui-local.scss";

html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// =================================================================
// TABLES
// =================================================================
td,
th {
  white-space: nowrap;
  border-color: transparent !important;
  border-right-color: var(--tui-base-04);

  &:last-child {
    border-right-color: transparent !important;
  }
}

tbody {
  border-color: transparent !important;
}

tr:nth-child(even) td {
  background: var(--tui-base-02);
}

.action-button {
  @include transition(opacity);
  opacity: 0;

  tr:hover &,
  tr:focus-within & {
    opacity: 1;
  }
}


// =================================================================
// SPACING
// =================================================================
.d-flex { display: flex; }
.d-flex-column { display: flex; flex-direction: column; }
.align-items-center { align-items: center; }
.align-items-start { align-items: start; }
.align-items-end { align-items: end; }
.justify-content-center { justify-content: center; }
.justify-content-start { justify-content: start; }
.justify-content-end { justify-content: flex-end; }
.justify-content-between { justify-content: space-between; }
.justify-content-around { justify-content: space-around; }
.flex-grow-1 { flex-grow: 1; }
.flex-grow-0 { flex-grow: 0; }
.flex-shrink-1 { flex-shrink: 1; }
.flex-shrink-0 { flex-shrink: 0; }
.w-100 { width: 100%; }
.w-0 { width: 0; }
.h-100 { height: 100%; }
.h-0 { height: 0; }
.overflow-hidden { overflow: hidden; }


$base-unit: var(--tui-padding-l, 1rem);

@for $i from 0 through 12 {
  .gap-#{$i} {
    gap: calc($base-unit * $i);
  }

  .p-#{$i} {
    padding: calc($base-unit * $i);
  }
  .py-#{$i} {
    padding-bottom: calc($base-unit * $i) !important;
    padding-top: calc($base-unit * $i) !important;
  }
  .px-#{$i} {
    padding-left: calc($base-unit * $i) !important;
    padding-right: calc($base-unit * $i) !important;
  }
  .pb-#{$i} {
    padding-bottom: calc($base-unit * $i) !important;
  }
  .pt-#{$i} {
    padding-top: calc($base-unit * $i) !important;
  }
  .pr-#{$i} {
    padding-right: calc($base-unit * $i) !important;
  }
  .pl-#{$i} {
    padding-left: calc($base-unit * $i) !important;
  }

  .m-#{$i} {
    margin: calc($base-unit * $i);
  }
  .my-#{$i} {
    margin-bottom: calc($base-unit * $i) !important;
    margin-top: calc($base-unit * $i) !important;
  }
  .mx-#{$i} {
    margin-left: calc($base-unit * $i) !important;
    margin-right: calc($base-unit * $i) !important;
  }
  .mb-#{$i} {
    margin-bottom: calc($base-unit * $i) !important;
  }
  .mt-#{$i} {
    margin-top: calc($base-unit * $i) !important;
  }
  .mr-#{$i} {
    margin-right: calc($base-unit * $i) !important;
  }
  .ml-#{$i} {
    margin-left: calc($base-unit * $i) !important;
  }

}
